@font-face {
  font-family: fraktion-sans-regular;
  src: url(Fraktion-Sans-Regular.ttf);
}

body {
  margin: 0;
  background-color: #265089;
}

p {
  color: white;
  font-family: fraktion-sans-regular;
}

h1 {
  color: white;
  font-family: fraktion-sans-regular;
}

.App {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100vw;
  margin-top: 5px;
}

@media screen and (min-width: 780px) {
  .App {
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: initial;
    height: 100vh;
  }
}

.App-logo {
  height: 94px;
}

header {
  display: flex;
  flex-direction: row;
  text-align: left;
  font-size: 27px;
}

header p {
  margin-top: 5px;
  margin-left: 5px;
}

header a {
  text-decoration: none;
}

#sidebar {
  text-align: left;
  width: 90%;
  padding: 24px 26px 0px 24px;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 780px) {
  #sidebar {
    width: 30%;
  }
}

#map-container {
  height: 60vh;
  width: 98%;
}

@media screen and (min-width: 780px) {
  #map-container {
    height: initial;
    width: 70%;
    padding: 0.5%;
  }
}

#description-text a:link {
  color: #96c2ff;
}

#description-text a:visited {
  color: #96c2ff;
}

.white-line {
  width: 95%;
  border-top: solid 1px white;
  display: block;
  margin-top: 10px;
}

#scrollable-area {
  margin-top: 16px;
  flex-grow: 1;
  height: 40%;
  overflow-y: scroll;
  overflow-x: hidden;
}

#search-box {
  width: 90%;
  background-color: rgba(255, 255, 255, 0.16);
  color: white;
  padding: 10px;
  border-radius: 6px;
  border: 0px;
}

#search-box-label {
  margin-top: 10px;
}

#search-box::placeholder {
  color: #dfdfdf;
  font-family: fraktion-sans-regular;
}

.white-line.second {
  margin-top: 20px;
  width: 100%;
}

#section-filter {
  height: 5rem;
  width: 95%;
}

#subtitle-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

.filter {
  position: relative;
  background-color: #265089;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.filter-closed {
  justify-content: center;
  height: fit-content;
}

.filter p {
  margin: 0px;
}

.filter-open {
  width: 100%;
  border: solid 1px rgba(255, 255, 255, 0.12);
  border-radius: 6px;
  align-items: flex-end;
}

#filter-toggle {
  display: flex;
  align-items: flex-end;
  margin-right: 8px;
}

.filter-open #filter-toggle {
  margin: 15px 7px;
}

.filter-icon {
  height: 16px;
}

.filter-option {
  display: flex;
  flex-direction: row;
}

.filter-option-icon {
  display: inline-block;
  width: 20px;
}

#select-container {
  width: 100%;
}

.select-options {
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.24);
  width: 96%;
  display: flex;
  flex-direction: column;
  margin: 2%;
}

.select-options p {
  margin: 0.5rem;
}

.select-options img {
  margin: 0.5rem;
}

.select-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 6px 6px 0px 0px;
}

.chevron {
  width: 18px;
  height: 9px;
}

.rotated {
  transform: rotate(-90deg);
}

.indy-pill {
  color: #265089;
  background-color: #cbe6ff;
  border-radius: 50px;
  font-size: 13px;
  padding: 2px 10px;
}

.indy-pill.card-active {
  color: #cbe6ff;
  background-color: #265089;
}

.outlet-card:hover .indy-pill {
  color: #cbe6ff;
  background-color: #265089;
}

.corp-pill {
  color: #8e4a1c;
  background-color: #fbe8c2;
  border-radius: 50px;
  font-size: 13px;
  padding: 2px 10px;
}

.corp-pill.card-active {
  color: #fbe8c2;
  background-color: #8e4a1c;
}

.outlet-card:hover .corp-pill {
  color: #fbe8c2;
  background-color: #8e4a1c;
}

.blue-pill {
  color: #265089;
  border: solid 1px #265089;
  border-radius: 50px;
  font-size: 13px;
  padding: 2px 10px;
  margin-bottom: 5px;
  width: fit-content;
}

.corp-owner {
  color: #8e4a1c;
}

.arrow {
  height: 1.8rem;
  cursor: pointer;
}

.link-arrow {
  height: 0.5rem;
  cursor: pointer;
  padding-left: 6px;
}

.site-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

/*
#outlet-list-container {
  flex-grow: 1;
  height: 40%;
  overflow-y: scroll;
  overflow-x: hidden;
}
*/

.outlet-card {
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  margin: 20px;
  border-radius: 6px;
  text-align: left;
}

.outlet-card:hover.independent {
  background-color: #cbe6ff;
}

.outlet-card.independent.card-active {
  background-color: #cbe6ff;
}

.outlet-card:hover.corporate {
  background-color: #fbe8c2;
}

.outlet-card.corporate.card-active {
  background-color: #fbe8c2;
}

.outlet-card p {
  color: #265089;
}

.outlet-title {
  margin: 0px;
}

.outlet-card section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.outlet-card aside {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.outlet-card aside p {
  margin: 5px;
}

/* Map Styles */

.location-label {
  z-index: 0;
  color: #265089;
  background-color: white;
  border: 1px #265089 solid;
  padding: 3px;
  border-radius: 6px;
}

.location-label-text {
  color: #265089;
  margin: 0;
}

.marker-container {
  opacity: 0;
}

.marker-container:hover {
  opacity: 1;
}

.outlet-marker {
  z-index: 1;
  opacity: 1;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  border: solid 3px white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.outlet-marker:hover {
  z-index: 2;
}

.outlet-marker.blue {
  background-color: #96c2ff;
}

.outlet-marker.blue.active {
  border-color: #265089;
}

.outlet-marker.orange {
  background-color: #e2a242;
}

.outlet-marker.orange.active {
  border-color: #8e4a1c;
}

.outlet-marker .outlet-tooltip {
  z-index: 3;
  visibility: hidden;
  position: relative;
  bottom: 40px;
  text-align: center;
  width: fit-content;
  white-space: nowrap;
}

.outlet-tooltip {
  z-index: 4;
  position: relative;
}

.outlet-marker:hover .outlet-tooltip {
  visibility: visible;
}

.outlet-tooltip.active {
  visibility: visible;
}

.outlet-owner-marker {
  position: relative;
  top: 5px;
  z-index: 2;
  opacity: 1;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.outlet-owner-marker.blue {
  background-color: #265089;
}

.outlet-owner-marker.orange {
  background-color: #8e4a1c;
}

.outlet-owner-marker.cluster {
  background-color: initial;
}

.close-icon {
  padding-left: 10px;
  cursor: pointer;
  position: relative;
  bottom: 7px;
}

.ownership-card {
  z-index: 0;
  position: relative;
  border-radius: 6px;
  padding: 10px;
}

.ownership-card.cluster {
  margin-top: 3rem;
}

.ownership-card.blue {
  background-color: #cbe6ff;
}

.ownership-card.orange {
  background-color: #fbe8c2;
}

.ownership-card-text {
  margin: 0px;
}

.ownership-card-text.blue {
  color: #265089;
}

.ownership-card-text.orange {
  color: #8e4a1c;
}

#fbukg {
  background-color: #265089;
}

#legend-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 9rem;
  top: 1rem;
  left: 1rem;
  z-index: 4;
  justify-content: space-between;
}

#legend-gradient {
  width: 6rem;
  height: 0.5rem;
}

#legend-text-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.legend-text {
  color: #265089;
  margin: 0;
}

.outlet-marker.cluster {
  justify-content: center;
}

.outlet-marker-number {
  text-align: center;
  margin: 0rem;
  font-size: 1.25rem;
  font-family: sans-serif;
}

.outlet-cluster-centre {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  opacity: 1;
  position: absolute;
  left: 11px;
  top: 11px;
}

.outlet-cluster-centre.blue {
  background-color: #96c2ff;
}

.outlet-cluster-centre.orange {
  background-color: #e2a242;
}
